* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 95vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav {
  width: 95%;
  height: 80px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav .logo {
  color: white;
  font-family: sans-serif;
  cursor: pointer;
  font-size: 1.5rem;
}
.nav .btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav .btns .service-btn {
  background-color: #145da0;
  padding: 10px 15px;
  font-family: sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  transition: 0.2s ease-in-out;
}
.nav .btns .service-btn:hover {
  background-color: #1876cd;
}
.nav .btns .newsletter-btn {
  color: white;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
  margin-left: 20px;
}

.container .header-section {
  height: 100%;

  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* 
animation
 */
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* animation */
.container .header-section .inner-header-section {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.container .header-section .inner-header-section h1 {
  font-size: 3rem;
  width: 100%;
  text-align: left;
}

/* .container .header-section .inner-header-section h1 span {
  font-size: 2rem;
  width: 100%;
  text-align: left;
  font-weight: 500;
} */
.container .header-section .inner-header-section p {
  font-size: 1.2rem;
  width: 85%;
  text-align: left;
  line-height: 30px;
}

@media (max-width: 1000px) {
  .container .header-section {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .header-section .inner-header-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .container .header-section .inner-header-section p {
    font-size: 1.3rem;
    width: 100%;
    text-align: center;

    line-height: 30px;
  }
  .container .header-section .inner-header-section h1 {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }

  .container .header-section .header-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .container .header-section .inner-header-section {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .container .header-section .inner-header-section p {
    font-size: 1.2rem;
    width: 75%;
    text-align: left;

    line-height: 30px;
  }
  .container .header-section .inner-header-section h1 {
    font-size: 3rem;
    width: 100%;
    text-align: left;
  }
}

.container .header-section .header-btns {
  display: flex;
  padding-top: 10px;
  width: 80%;
}

.container .header-section h1 {
  color: white;
  font-size: 4rem;
}

.container .header-section p {
  color: white;
  font-size: 1rem;
  font-family: sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
}

.container .header-section .header-btns button:first-of-type {
  font-size: 1.2rem;
  font-family: sans-serif;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5%;
  background-color: #145da0;
  border: none;
  color: white;
  width: 170px;
  padding: 12px 10px;
  transition: 0.1s ease-in;
}

.container .header-section .header-btns button:hover {
  background-color: #1876cd;
}

/* about */

.about {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-direction: column;
}

.inner-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 900px;
  line-height: 1.5;
  text-align: center;
}
.about .about-header {
  height: 100px;
  display: flex;
  align-items: center;
}

.about .about-header h1 {
  color: #20222b;
  font-size: 2rem;
  font-family: sans-serif;
}

.about .inner-about .box-for-text {
  height: 420px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about p {
  height: 420px;
  color: #5f5f5f;
  margin: 0;
  padding: 0px 10px;
  width: 100%;
  line-height: 44px;
  font-size: 1.2rem;
  font-family: sans-serif;
}

@media (min-width: 700px) and (max-width: 900px) {
  .about h1 {
    color: #20222b;
    font-size: 1.4rem;
    font-family: sans-serif;
  }
  .about .about-header {
    height: 100px;
    display: flex;
    align-items: flex-end;
  }
  .about .inner-about .box-for-text {
    height: 450px;
    width: 100%;
  }
  .about p {
    max-height: 500px;
    color: #5f5f5f;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    line-height: 38px;

    font-size: 1.1rem;
    font-family: sans-serif;
  }
}

@media (max-width: 700px) {
  .about h1 {
    color: #20222b;
    font-size: 1.4rem;
    font-family: sans-serif;
  }
  .about .about-header {
    height: 100px;
    display: flex;
    align-items: center;
  }
  .about p {
    max-height: 100%;
    color: #5f5f5f;
    padding: 0px 20px;
    line-height: 34px;

    font-size: 0.9rem;
    font-family: sans-serif;
  }
}

@media (max-width: 430px) {
  .about {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    flex-direction: column;
  }

  .inner-about {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    max-width: 900px;
    line-height: 1.5;
    text-align: center;
  }
  .about .about-header {
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .about .about-header h1 {
    color: #20222b;
    font-size: 2rem;
    font-family: sans-serif;
  }

  .about .inner-about .box-for-text {
    height: 550px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about p {
    height: 550px;
    color: #5f5f5f;
    margin: 0;
    padding: 0px 10px;
    width: 100%;
    line-height: 30px;
    font-size: 1rem;
    font-family: sans-serif;
  }
}

/* servicesss */

.third-section {
  height: 100%;
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background: #20222b; */
}

.third-section .service-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.third-section .service-header h1 {
  color: white;
  font-size: 2rem;

  font-family: sans-serif;
}
.third-section .service-box {
  padding: 20px;
  width: 100%;
  color: rgb(41, 41, 41);
  display: grid;
  grid-template-columns: 450px 450px;
  grid-template-rows: 300px 300px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.third-section .service-box .service-item {
  border: 1px solid white;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  width: 330px;
  text-align: center;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.third-section .service-box .service-item:hover {
  opacity: 0.9;
  transform: scale(1.04);
}

.third-section .service-box .service-item h3 {
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.third-section .service-box .service-item p {
  font-size: 1rem;
  line-height: 1.5;
  font-family: sans-serif;
}

@media screen and (min-width: 670px) and (max-width: 1000px) {
  .third-section .service-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .third-section .service-header h1 {
    color: white;
    font-size: 1.5rem;

    font-family: sans-serif;
  }
  .third-section .service-box {
    width: 100%;
    color: rgb(41, 41, 41);
    display: grid;
    grid-template-columns: 350px 350px;
    grid-template-rows: 300px 300px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
  .third-section .service-box .service-item {
    border: 1px solid white;
    padding: 10px;
    background-color: white;

    border-radius: 10px;

    cursor: pointer;
    width: 250px;
    text-align: center;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.3s ease-in-out;
  }

  .third-section .service-box .service-item:hover {
    opacity: 0.9;
    transform: scale(1.04);
  }

  .third-section .service-box .service-item h3 {
    font-size: 1.1rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  .third-section .service-box .service-item p {
    font-size: 0.9rem;
    line-height: 1.5;
    font-family: sans-serif;
  }
}

@media screen and (max-width: 670px) {
  .third-section {
    height: 100%;
    width: 100%;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* background: #20222b; */
  }

  .third-section .service-header {
    height: 50px;
    display: flex;
    padding-top: 5px;

    align-items: center;
    justify-content: space-around;
  }
  .third-section .service-header h1 {
    color: white;
    font-size: 1.5rem;
    font-family: sans-serif;
  }
  .third-section .service-box {
    padding: 20px;
    width: 100%;
    color: rgb(41, 41, 41);
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;
    align-items: center;
  }
  .third-section .service-box .service-item {
    border: 1px solid white;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    width: 330px;
    text-align: center;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.3s ease-in-out;
  }

  .third-section .service-box .service-item:hover {
    opacity: 0.9;
    transform: scale(1.04);
  }

  .third-section .service-box .service-item h3 {
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  .third-section .service-box .service-item p {
    font-size: 1rem;
    line-height: 1.5;
    font-family: sans-serif;
  }
}
/* fourr */

@media screen and (min-width: 901px) {
  .section-four {
    height: 100%;

    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 500px 400px;
  }

  .section-four .calendar {
    grid-column: 1;
    grid-row: 1/3;

    text-align: center;
  }

  .section-four .four-text {
    grid-column: 2;
    grid-row: 1/2;

    margin-left: 10px;
  }

  .section-four .four-text-end {
    grid-column: 2;
    grid-row: 2/3;
    margin-left: 10px;
  }
  .calendly-inline-widget {
    max-width: 550px;
    margin: 0 auto;
    overflow: hidden;
  }
  .image-container {
    border: 4px solid #145da0;
    margin-bottom: 20px;
    width: 150px; /* Adjust the width and height based on your image size */
    height: 150px;
    border-radius: 50%; /* Apply a border radius of 50% to create a circle */
    overflow: hidden; /* Hide any overflowing content */
  }

  .circle-image {
    width: 100%; /* Ensure the image takes up the full container space */
    height: 100%;
    object-fit: cover; /* Maintain the aspect ratio and cover the container */
  }

  .section-four .four-text .header1 {
    font-family: sans-serif;
    font-size: 22px;
    color: #145da0;

    font-weight: 700;
  }
  .section-four .four-text .header2 {
    font-family: sans-serif;
    font-size: clamp(15px, 3.5vw, 40px);
    word-break: normal;
    color: #1d1b1b;
    font-weight: 700;
  }
  .section-four .four-text .paragrap {
    font-family: sans-serif;
    font-size: 18px;
    margin-top: 10px;
    color: #767676;
    line-height: 30px;
    max-width: 500px;
    font-weight: 300;
  }
  .paragrap2 {
    font-family: sans-serif;
    font-size: 18px;
    color: #767676;
    line-height: 30px;
    max-width: 500px;
    font-weight: 200;
    margin-top: 30px;
  }

  .header3 {
    font-family: sans-serif;
    font-size: 22px;
    color: #145da0;

    font-weight: 700;
  }
  .section-four .four-text-end .perfect-for {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
  }

  .section-four .four-text-end .perfect-for li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #1d1b1b;
    max-width: 500px;

    line-break: normal;

    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    line-height: 30px;
  }
  .section-four .four-text-end .perfect-for li .inner-check {
    display: flex;
    margin-top: 5px;
    align-items: center;

    width: 30px;
    color: #145da0;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four {
    width: 100%;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four span {
    color: #20222b;
    font-weight: bold;
    margin-left: 5px;
  }
}
@media (max-width: 900px) {
  .section-four {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 450px 900px 250px;
  }

  .section-four .calendar {
    grid-row: 2;
    width: 100%;
  }
  .calendly-inline-widget {
    max-width: 550px;
    margin: 0 auto;
    overflow: hidden;
  }
  .section-four .four-text {
    grid-row: 1;
    width: 100%;
    padding: 10px;
  }
  .section-four .four-text-end {
    grid-row: 3;
    width: 100%;
    padding: 10px;
  }

  .image-div-box {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .image-container {
    border: 4px solid #145da0;

    margin-bottom: 20px;
    width: 150px; /* Adjust the width and height based on your image size */
    height: 150px;
    border-radius: 50%; /* Apply a border radius of 50% to create a circle */
    overflow: hidden; /* Hide any overflowing content */
  }
  .circle-image {
    width: 100%; /* Ensure the image takes up the full container space */
    height: 100%;
    object-fit: cover; /* Maintain the aspect ratio and cover the container */
  }
  .section-four .four-text .header1 {
    font-family: sans-serif;
    font-size: 18px;
    color: #145da0;
    text-align: start;
    font-weight: 700;
  }
  .section-four .four-text .header2 {
    font-family: sans-serif;
    font-size: 32px;
    color: #1d1b1b;
    text-align: start;
    font-weight: 700;
  }
  .section-four .four-text .paragrap {
    font-family: sans-serif;
    font-size: 16px;
    margin-top: 10px;

    color: #767676;
    line-height: 30px;
    text-align: left;
    width: 100%;
    padding-right: 5px;
    font-weight: 300;
  }
  .paragrap2 {
    font-family: sans-serif;
    font-size: 16px;
    color: #767676;
    text-align: left;
    line-height: 30px;
    width: 100%;
    font-weight: 200;

    margin-top: 10px;
  }

  .four-text-end .header3 {
    margin: 8px 0px;
    font-family: sans-serif;
    font-size: 22px;
    color: #145da0;
    text-align: left;
    font-weight: 700;
  }
  .section-four .four-text-end .perfect-for {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #1d1b1b;

    list-style: none;
  }

  .section-four .four-text-end .perfect-for li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #1d1b1b;
    line-break: normal;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 100%;
    line-height: 30px;
  }
  .section-four .four-text-end .perfect-for li .inner-check {
    margin-top: 3px;
    width: 30px;
    color: #145da0;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four {
    width: 100%;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four span {
    color: black;
    font-weight: bold;
    margin-left: 5px;
  }
}

@media (max-width: 430px) {
  .section-four {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 500px 900px 320px;
  }

  .section-four .calendar {
    grid-row: 2;
    width: 100%;
  }
  .calendly-inline-widget {
    max-width: 550px;
    margin: 0 auto;
    overflow: hidden;
  }
  .section-four .four-text {
    grid-row: 1;
    width: 100%;
    padding: 10px;
  }
  .section-four .four-text-end {
    grid-row: 3;
    width: 100%;
    padding: 10px;
  }

  .image-div-box {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .image-container {
    border: 4px solid #145da0;

    margin-bottom: 20px;
    width: 150px; /* Adjust the width and height based on your image size */
    height: 150px;
    border-radius: 50%; /* Apply a border radius of 50% to create a circle */
    overflow: hidden; /* Hide any overflowing content */
  }
  .circle-image {
    width: 100%; /* Ensure the image takes up the full container space */
    height: 100%;
    object-fit: cover; /* Maintain the aspect ratio and cover the container */
  }
  .section-four .four-text .header1 {
    font-family: sans-serif;
    font-size: 18px;
    color: #145da0;
    text-align: start;
    font-weight: 700;
  }
  .section-four .four-text .header2 {
    font-family: sans-serif;
    font-size: 28px;
    color: #1d1b1b;
    text-align: start;
    font-weight: 700;
  }
  .section-four .four-text .paragrap {
    font-family: sans-serif;
    font-size: 14px;
    margin-top: 10px;

    color: #767676;
    line-height: 30px;
    text-align: left;
    width: 100%;
    padding-right: 5px;
    font-weight: 300;
  }
  .paragrap2 {
    font-family: sans-serif;
    font-size: 14px;
    color: #767676;
    text-align: left;
    line-height: 30px;
    width: 100%;
    font-weight: 200;

    margin-top: 10px;
  }

  .four-text-end .header3 {
    margin: 8px 0px;
    font-family: sans-serif;
    font-size: 20px;
    color: #145da0;
    text-align: left;
    font-weight: 700;
  }
  .section-four .four-text-end .perfect-for {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #1d1b1b;

    list-style: none;
  }

  .section-four .four-text-end .perfect-for li {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #1d1b1b;
    line-break: normal;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-width: 100%;
    line-height: 30px;
  }
  .section-four .four-text-end .perfect-for li .inner-check {
    margin-top: 3px;
    width: 30px;
    color: #145da0;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four {
    width: 100%;
  }
  .section-four .four-text-end .perfect-for li .inner-perfect-four span {
    color: black;
    font-weight: bold;
    margin-left: 5px;
  }
}
/* 
footer */

.footer-section {
  height: 100%;
  padding: 20px;
  color: white;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.footer-section .footer-logo {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.footer-section .footer-text {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer-section .footer-text .combine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}
.footer-section .footer-text .inner-first {
  height: 100%;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-section .footer-text .inner-first button {
  background-color: #145da0;
  color: white;
  padding: 10px 15px;
  font-family: sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in;
  font-weight: 500;
}
.footer-section .footer-text .inner-first button:hover {
  background-color: #1876cd;
}

.footer-section .privacy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-section .privacy-content {
  display: flex;
  align-items: center;
}

.footer-section .privacy-content h3 {
  font-size: 0.9rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
}

@media (max-width: 750px) {
  .footer-section {
    padding: 10px;
    height: 100%;

    color: white;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footer-section .footer-text {
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  .footer-section .footer-text .inner-first {
    height: 70%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  .footer-section .footer-text .combine {
    width: 80%;
    display: flex;

    justify-content: space-between;
    align-items: center;
  }
}
